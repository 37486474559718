import React from 'react';
import './StaffListStyles.css';

const staffMembers = [
    {
        name: 'Pastor Aaron Goeke',
        image: 'pastor.jpg'
    },
    {
        name: 'President Jarod Cain',
        image: 'president.jpg'
    },
    {
        name: 'Vice-President Clyde Muennink',
        image: 'vice-president.jpg'
    },
    {
        name: 'Secretary Paige Suehs',
        image: 'secretary.jpg'
    },
    {
        name: 'Treasurer Neal Schneider',
        image: 'treasurer.jpg'
    },
    {
        name: 'At-Large Farrah Schueling',
        image: 'at-large-1.jpg'
    },
    {
        name: 'At-Large Stanley Meyer',
        image: 'at-large-2.jpg'
    }
];

const StaffList = () => {
    return (
        <div className='staff-list'>
            {staffMembers.map((member, index) => (
                <div key={index} className='staff-member'>
                    <img src={member.image} alt={member.name}/>
                    <span style={{color: 'white'}}>{member.name}</span>
                </div>
            ))};
        </div>
    );
};

export default StaffList;
