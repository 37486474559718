import "./HeroImgStyles.css";

import React from 'react';
import IntroImg from "../assets/intro-bg.jpg";
import Newsletter from "../assets/current-newsletter-download.pdf";

const HeroImg = () => {
    return (
        <div className="hero">
            <div className="mask">
                <img className="intro-img" src={IntroImg} alt="Quihi Bethlehem Lutheran Church"/>
            </div>
            <div className="content">
                <p>"Called by Grace, Led by Faith, Welcoming All to Share God's Love at Home, with our Neighbors and throughout the World."</p>
                <h1>QUIHI BETHLEHEM LUTHERAN CHURCH</h1>
                <div>
                    <a className="btn" href={Newsletter} download>NEWSLETTER</a>
                    <a className="btn btn-light" href="https://calendar.google.com/calendar/u/0/embed?src=quihichurchnews@gmail.com&ctz=America/Chicago">CALENDAR</a>
                </div>
            </div>
        </div>
    )
};

export default HeroImg;
