import React from 'react';

import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroImg2 from '../components/HeroImg2';
import Form from '../components/ChapelForm';
import Slideshow from '../components/ChapelSlideshow';
import '../components/ChapelStyles.css';

const Chapel = () => {
    return (
        <div>
            <NavBar />
            <HeroImg2 heading="RENTALS" text="Chapel"/>
            <Slideshow />
            <h1 className='centered-text'>GUIDELINES FOR RENTAL AND USE OF CHURCH OR CHAPEL</h1>
            <div style={{ height: '20px' }}></div>
            <p className='centered-text' style={{ textAlign: 'left' }}>The congregation of the Quihi Bethlehem Lutheran Church wishes to help the members and the people in the community by allowing them to use the Church (which will include minimal use of the Education Building for bathroom and dressing needs.) Use of the Fellowship Hall requires a different contract. Church Functions will have priority in scheduling. Quihi Bethlehem Lutheran Church has the right to refuse anyone the use of the building.</p>
            <div style={{ height: '20px' }}></div>
            <h4 className='centered-text'>The following guidelines are set to govern the use of the Church, Chapel and Education Building by individuals or groups.</h4>
            <ol className='centered-text'>
                <li style={{ color: 'white' }}>The applicant’s request shall be in writing on the provided request form and include reasonable detail of the proposed usage. No reservation may be made more than one year prior to the event.</li>
                <li style={{ color: 'white' }}>A Church Member requesting the Church or Chapel / Education Building, a Council Member <u>must</u> open, check for damages, and lock the facilities.</li>
                <li style={{ color: 'white' }}>Renter <b>WILL NOT charge any</b> admission fee for personal gain on any event that takes place on the Church premises.</li>
                <li style={{ color: 'white' }}>No church-owned property is to be taken from the premises.</li>
                <li style={{ color: 'white' }}>NO smoking is allowed inside any Church building.</li>
                <li style={{ color: 'white' }}>All church premises will be cleaned and returned to the condition found by renter.</li>
            </ol>
            <div style={{ height: '20px' }}></div>
            <h4 className='centered-text'><u>FOR MEMBERS RESERVING CHURCH OR CHAPEL:</u></h4>
            <p className='centered-text'>No fee</p>
            <div style={{ height: '20px' }}></div>
            <h4 className='centered-text'><u>FOR NON-MEMBERS RESERVING CHURCH:</u></h4>
            <ul className='centered-text'>
                <li style={{ color: 'white' }}><b>$300.00</b> check paid to Quihi Bethlehem Lutheran Church</li>
                <li style={{ color: 'white' }}><b>$100.00*</b> check per every additional day used (set-up/take-down), paid to QBLC.</li>
            </ul>
            <div style={{ height: '20px' }}></div>
            <h4 className='centered-text'><u>FOR NON-MEMBERS RESERVING CHAPEL:</u></h4>
            <ul className='centered-text'>
                <li style={{ color: 'white' }}><b>$150.00</b> check paid to Quihi Bethlehem Lutheran Church</li>
                <li style={{ color: 'white' }}><b>$100.00*</b> check per every additional day used (set-up/take-down), paid to QBLC.</li>
            </ul>
            <div style={{ height: '20px' }}></div>
            <h4 className='centered-text'>Additional Information:</h4>
            <ol className='centered-text'>
                <li style={{ color: 'white' }}>Please submit checks to <u>Quihi Bethlehem Lutheran Church, 3901 FM 2676, Hondo, TX 78861</u></li>
                <li style={{ color: 'white' }}>The user shall pay the Church the full amount at the time the contract is signed. If the event is cancelled fifteen (15) days prior to the event, the entire amount will be refunded.</li>
                <li style={{ color: 'white' }}>If the renter uses an "outside" pastor, this must be approved by the Church Council.</li>
                <li style={{ color: 'white' }}>When rented by Non-Members, it will be necessary for a Church Council Member to open, check for damages, and lock the church/chapel and Education Building.</li>
                <li style={{ color: 'white' }}>This amount does not include any gratuity for our Pastor. All required premarital counseling includes $35 per couple for curriculum.</li>
            </ol>
            <Form />
            <h4 className='centered-text'>General Guidelines</h4>
            <ol className='centered-text'>
                <li style={{ color: 'white' }}>The applicant’s request shall be in writing on the form provided and include proposed usage.</li>
                <li style={{ color: 'white' }}>Please bag & dispose of trash from Church/Chapel and dressing areas. Place in available trash dumpsters found to the left of the church.</li>
                <li style={{ color: 'white' }}>Decorations must be removed. Any altar flowers may be left for Church services.</li>
                <li style={{ color: 'white' }}>Be sure to turn off all fans, thermostats, lights, and any audio equipment that may have been used.</li>
                <li style={{ color: 'white' }}>NO RICE or CONFETTI WILL BE ALLOWED INSIDE OR OUTSIDE THE BUILDINGS. Birdseed is allowed outside only.</li>
            </ol>
            <div style={{ height: '20px' }}></div>
            <h4 className='centered-text'><b>QUIHI BETHLEHEM LUTHERAN CHURCH WILL <u>NOT</u> BE RESPONSIBLE FOR ANY PERSONAL INJURY OR LOSS OCCURRING ON THE PREMISES.</b></h4>
            <div style={{ height: '20px' }}></div>
            <h4 className='centered-text'><b>USER IS RESPONSIBLE FOR ANY DAMAGE TO THE BUILDING OR CONTENTS.</b></h4>
            <div style={{ height: '20px' }}></div>
            <h4 className='centered-text'><b>Failure to follow these guidelines could be cause for immediate removal from the property and forfeiture of rental fee.</b></h4>
            <p className='centered-text'>By submitting this form, you agree to abide by and follow the guidelines and procedures set forth in this contract for usage of the Quihi Bethlehem Lutheran Church or Chapel / Education Building.</p>
            <div style={{ height: '20px' }}></div>
            <Footer />
        </div>
    );
};

export default Chapel;
