import "./FormStyles.css";
import emailjs from 'emailjs-com';

import React, { useState } from 'react';

const FellowshipHallForm = () => {
    const [formData, setFormData] = useState({
        building: 'Fellowship Hall',
        typeOfFunction: '',
        sponsorName: '',
        responsiblePerson: '',
        address: '',
        phoneNumber: '',
        emailAddress: '',
        dateOfEvent: '',
        startTime: '',
        finishTime: '',
        needExtraSetUpDay: false,
        extraDayDate: '',
        extraDayStartTime: '',
        extraDayFinishTime: '',
        numberOfPeopleExpected: '',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const dataToSend = {
            building: formData.building,
            typeOfFunction: formData.typeOfFunction,
            sponsorName: formData.sponsorName,
            responsiblePerson: formData.responsiblePerson,
            address: formData.address,
            phoneNumber: formData.phoneNumber,
            emailAddress: formData.emailAddress,
            dateOfEvent: formData.dateOfEvent,
            startTime: formData.startTime,
            finishTime: formData.finishTime,
            needExtraSetUpDay: formData.needExtraSetUpDay ? 'Yes' : 'No',
            extraDayDate: formData.extraDayDate,
            extraDayStartTime: formData.extraDayStartTime,
            extraDayFinishTime: formData.extraDayFinishTime,
            numberOfPeopleExpected: formData.numberOfPeopleExpected,
        };

        emailjs.send('service_u5hq6wj', 'template_4s5ysqc', dataToSend, 'DCtlUECHUScupa85j')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Rental request sent successfully!');
            }, (err) => {
                console.log('FAILED...', err);
                alert('An error occurred, please try again later.');
            });
        e.target.reset();
    };

    return (
        <div className="form">
            <form onSubmit={handleSubmit}>
                <label>Type of Function</label>
                <input required
                    type="text"
                    name="typeOfFunction"
                    value={formData.typeOfFunction}
                    onChange={handleChange}
                />
                <label>Name of Individual(s) or Sponsor(s)</label>
                <input required
                    type="text"
                    name="sponsorName"
                    value={formData.sponsorName}
                    onChange={handleChange}
                />
                <label>Name of Responsible Person</label>
                <input required
                    type="text"
                    name="responsiblePerson"
                    value={formData.responsiblePerson}
                    onChange={handleChange}
                />
                <label>Address</label>
                <input required
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                />
                <label>Phone Number</label>
                <input required
                    type="tel"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                />
                <label>Email Address</label>
                <input required
                    type="text"
                    name="emailAddress"
                    value={formData.emailAddress}
                    onChange={handleChange}
                />
                <label>Date of Event</label>
                <input required
                    type="date"
                    name="dateOfEvent"
                    value={formData.dateOfEvent}
                    onChange={handleChange}
                />
                <label>Start Time</label>
                <input required
                    type="time"
                    name="startTime"
                    value={formData.startTime}
                    onChange={handleChange}
                />
                <label>Finish Time</label>
                <input required
                    type="time"
                    name="finishTime"
                    value={formData.finishTime}
                    onChange={handleChange}
                />
                <div className='form-group'>
                    <label>
                        <input
                            type="checkbox"
                            name="needExtraSetUpDay"
                            checked={formData.needExtraSetUpDay}
                            onChange={handleChange}
                        />
                        Need Extra Set Up Day? ($100 per day for non-members only)
                    </label>   
                </div>
                {formData.needExtraSetUpDay && (
                    <div className='form-group'>
                        <label>Extra Day Date</label>
                        <input
                            type="date"
                            name="extraDayDate"
                            value={formData.extraDayDate}
                            onChange={handleChange}
                        />
                        <label>Start Time</label>
                        <input
                            type="time"
                            name="extraDayStartTime"
                            value={formData.extraDayStartTime}
                            onChange={handleChange}
                        />
                        <label>End Time</label>
                        <input
                            type="time"
                            name="extraDayFinishTime"
                            value={formData.extraDayFinishTime}
                            onChange={handleChange}
                        />
                    </div>
                )}
                <label>Approximate Number of People Expected</label>
                <input required
                    type="number"
                    name="numberOfPeopleExpected"
                    value={formData.numberOfPeopleExpected}
                    onChange={handleChange}
                />
                <button className="btn" type="submit">Submit</button>
            </form>
        </div>
    );
};

export default FellowshipHallForm;
