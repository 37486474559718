import React from 'react';
import './index.css';
import Home from './routes/Home';
import About from './routes/About';
import Project from './routes/Project';
import Contact from './routes/Contact';
import Chapel from './routes/Chapel';
import Church from './routes/Church';
import FellowshipHall from './routes/FellowshipHall';
import InterestBoard from './routes/InterestBoard';
import ScrollToTop from './components/ScrollToTop';
import { Route, Routes } from 'react-router-dom';

function App() {
	return (
		<div>
			<>
			<ScrollToTop />
			<div id='main-content'>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/project" element={<Project />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/chapel" element={<Chapel />} />
					<Route path="/church" element={<Church />} />
					<Route path="/fellowship-hall" element={<FellowshipHall />} />
					<Route path="/interest-board" element={<InterestBoard />} />
				</Routes>
			</div>
			</>
		</div>
	);
};

export default App;
