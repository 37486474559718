import "./AboutContentStyles.css";
import Image1 from "../assets/image1.jpg";
import Image2 from "../assets/image2.jpg";

import React from 'react';

const AboutContent = () => {
    return (
        <div>
            <div style={{ height: '20px' }}></div>
            <h1 className="centered-text">HISTORY</h1>
            <div style={{ height: '20px' }}></div>
            <p className="centered-text" style={{textAlign: 'left'}}>In <b>1846</b> Christian Germans immigrated from <a href="https://en.wikipedia.org/wiki/Baden-W%C3%BCrttemberg">Württemberg</a>, <a href="https://en.wikipedia.org/wiki/Hanover">Hanover</a>, <a href="https://en.wikipedia.org/wiki/Alsace">Alsace</a>, <a href="https://en.wikipedia.org/wiki/Baden">Baden</a>, and <a href="https://en.wikipedia.org/wiki/Frisia">East Frisia</a> and settled in the <a href="https://en.wikipedia.org/wiki/Quihi,_Texas">Quihi</a> area of <a href="https://en.wikipedia.org/wiki/Medina_County,_Texas">Medina County</a>. Their Christian faith helped them to get through many difficulties and adversities of the Texas Frontier.</p>
            <div style={{ height: '20px' }}></div>
            <p className="centered-text" style={{textAlign: 'left'}}>They began gathering to worship under the live oak trees on this church property and with the help of the Lutheran Pastor, Christian Oefinger, a congregation was founded in <b>1852</b>. Three <a href="https://www.swtsynod.org/">Texas Synod</a> meetings were hosted at Quihi Bethlehem Lutheran Church prior to <b>1894</b>.</p>
            <div style={{ height: '20px' }}></div>
            <p className="centered-text" style={{textAlign: 'left'}}>In <b>1857</b> the <a href="https://www.tshaonline.org/handbook/entries/quihi-tx">first public school</a> of Quihi had classes held in the church. Early records show that before <b>1865</b> a parsonage was built and expanded. In <b>1874</b> a new parsonage was built. In <b>1927</b> a Parish Hall was built and in <b>1952</b> additions were made to include a kitchen, restrooms, and classrooms.</p>
            <div style={{ height: '20px' }}></div>
            <p className="centered-text" style={{textAlign: 'left'}}>In <b>1950</b> a new parsonage was built until a fire in the kitchen resulted in a new parsonage being built which stands today. A Prayer Garden was built where the <b>1952</b> parsonage was. Additions to the Parish Hall (now Education Building) was made in <b>1972</b>. A large Fellowship Hall was built in <b>2001</b>.</p>
            <div style={{ height: '20px' }}></div>
            <p className="centered-text" style={{textAlign: 'left'}}>The original sanctuary was damaged by a storm in <b>1912</b> and the present red brick sanctuary was built in <b>1913</b> and dedicated in <b>1914</b>. The adjoining consecrated cemetery was established in <b>1864</b> and its use was discontinued in <b>1936</b>.</p>
            <div style={{ height: '20px' }}></div>
            <p className="centered-text" style={{textAlign: 'left'}}>In <b>1949</b> the <a href="https://swtsynod.pastperfectonline.com/Archive/6CD1D9D5-0D08-45F1-B191-711714258823">St. John's Lutheran Church of New Fountain</a> merged with Quihi Bethlehem Lutheran Church.</p>
            <div style={{ height: '20px' }}></div>
            <p className="centered-text" style={{textAlign: 'left'}}>Annual Sausage Supper is held on the 2nd Saturday in October.</p>
            <div style={{ height: '20px' }}></div>
            <p className="centered-text" style={{textAlign: 'left'}}>Today this church stands as a legacy to the faith, hard work and visions of those members founded and maintained this congregation over the years.</p>
            <div style={{ height: '20px' }}></div>
            <div className="image-container">
                <img src={Image1} className="responsive-image" alt="true"/>
                <img src={Image2} className="responsive-image" alt="true"/>
            </div>
            
        </div>
    )
};

export default AboutContent;
