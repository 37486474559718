import pro1 from "../assets/project1.png";
import pro2 from "../assets/project2.png";
import pro3 from "../assets/project3.png";

const ProjectCardData = [
    {
        imgsrc: pro1,
        alt: "Sunday School",
        title: "Sunday School",
        text: "There are no Sunday School classes during the summer. Sunday School will resume after Labor Day.\nStarts @9am located in the Education Building. All kids are welcome.\nTeachers: Farrah Schueling, Cathy Schnitzer, Elaine Coyle, Renee Raygosa, and Deborah Waltz.",
    },
    {
        imgsrc: pro2,
        alt: "Adult Bible Study",
        title: "Adult Bible Study",
        text: "Starts @9am located in the Education Building. All are welcome.\nTeacher: Pastor Aaron Goeke",
    },
    {
        imgsrc: pro3,
        alt: "Sunday Service",
        title: "Sunday Service",
        text: "Starts @10am in the church. Service given by Pastor Aaron Goeke.\nHoly Communion is celebrated every 1st, 3rd, and 5th Sundays of the month.\nConfirmation Classes for 7th & 8th grade students held every 2nd & 4th Sundays after Worship.",
    },
];

export default ProjectCardData;
