import "./FooterStyles.css";
import StaffList from './StaffList';
import React from 'react';


import { FaHome, FaPhone, FaMailBulk, FaFacebook, FaYoutube } from "react-icons/fa";

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-container">
                <div className="left">
                    <div className="location">
                        <FaHome size={20} style={{color:"#fff", marginRight:"2rem"}} />
                        <div>
                            <p>3901 FM 2676, Hondo, TX 78861</p>
                        </div>
                    </div>
                    <div className="phone">
                        <h4><FaPhone size={20} style={{color:"#fff", marginRight:"2rem"}} />(830)-426-4636</h4>
                    </div>
                    <div className="email">
                        <h4><FaMailBulk size={20} style={{color:"#fff", marginRight:"2rem"}} />quihichurchnews@gmail.com</h4>
                    </div>
                    <div className="social">
                        <a href="https://www.facebook.com/quihibethlehemlutheran?mibextid=2JQ9oc" target="_blank" rel="noopener noreferrer">
                            <FaFacebook size={30} style={{color:"#fff", marginRight:"1rem"}} />
                        </a>
                        <a href="https://www.youtube.com/@qblc809" target="_blank" rel="noopener noreferrer">
                            <FaYoutube size={30} style={{color:"#fff", marginRight:"1rem"}} />
                        </a>
                    </div>
                </div>

                <div className="right">
                    <h4>Staff & Council</h4>
                    <StaffList />
                </div>
            </div>
        </div>
    );
};

export default Footer;
