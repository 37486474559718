import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './InterestBoard.module.css';  // Import the CSS module

function InterestBoard() {
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);

  useEffect(() => {
    axios.get('/interest-board/groups')
      .then(response => {
        setGroups(response.data);
      })
      .catch(error => {
        console.error('Error fetching the groups:', error);
      });
  }, []);

  const handleSubmit = (group) => (e) => {
    e.preventDefault();

    if (!captchaVerified) {
      alert('Please verify that you are not a robot :)');
      return;
    }

    axios.post('/interest-board/signup', {
      group_name: group.name,
      name: name,
      email: email,
    })
      .then(response => {
        alert(`Successfully signed up for ${group.name}`)
        setSelectedGroup('');
	setCaptchaVerified(false);
      })
      .catch(error => console.error('Error signing up:', error));
  };

  const handleSelectGroup = (group) => {
    setSelectedGroup(group.name);
  };

  const onCaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };

  return (
    <div className={styles.interestBoard}>
      <h1 className={styles.heading}>Interest Board</h1>
      <p>Welcome to our Quihi Bethlehem Lutheran Church (QBLC) Interest Groups web page.  Here is our current listing of all active QBLC groups as well as several potential groups that are seeking interested members.</p>
      <p>To contact a group with your interest or to ask questions, please fill in your name and email address and then select “submit”.  This will result in your info being forwarded to the group’s identified Point of Contact (POC) who will then contact you directly.</p>
      <div className={styles.groupGrid}>
        {groups.map(group => (
          <div
            key={group.name}
            className={`${styles.groupCard} ${group.status === 'established' ? styles.established : styles.potential}`}
          >
            <h2>{group.name}</h2>
            <p style={{color:"black"}}>{group.description}</p>
            <p style={{color:"black"}}>Contact: {group.contact}</p>
	    {selectedGroup === group.name ? (
	    <form onSubmit={handleSubmit(group)}>
              <input
                type="text"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className={styles.input}
              />
              <input
                type="email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={styles.input}
              />
              <ReCAPTCHA
	        sitekey="6Lc2VCUqAAAAAFvug_XgiDlTc96HNljru2-lBtGc"
		onChange={onCaptchaChange}
              />
              <button type="submit" className={styles.button}>Sign Up</button>
	    </form>
	    ) : (
		    <button onClick={() => handleSelectGroup(group)} className={styles.button}>Select to Sign Up</button>
	    )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default InterestBoard;

