import React from 'react';

import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroImg2 from '../components/HeroImg2';
import Form from '../components/FellowshipHallForm';
import Slideshow from '../components/FellowshipHallSlideshow';
import '../components/FellowshipHallStyles.css';

const FellowshipHall = () => {
    return (
        <div>
            <NavBar />
            <HeroImg2 heading="RENTALS" text="Fellowship Hall"/>
            <Slideshow />
            <h1 className='centered-text'>GUIDELINES FOR RENTAL AND USE OF THE FELLOWSHIP HALL</h1>
            <div style={{ height: '20px' }}></div>
            <p className='centered-text' style={{ textAlign: 'left' }}>The congregation of the Quihi Bethlehem Lutheran Church wishes to help the members and the people in the community by allowing them to use the Fellowship Hall. Areas to the Hall which may be used include the Fellowship Hall Kitchen, Meeting Room, and Outside Pavilion. The Quilting Room <u>may not</u> be included for rentals. Church Functions will have priority in scheduling. Quihi Bethlehem Lutheran Church has the right to refuse anyone the use of the building.</p>
            <div style={{ height: '20px' }}></div>
            <h4 className='centered-text'>The following guidelines are set to govern the use of the Fellowship Hall by individuals or groups.</h4>
            <ol className='centered-text'>
                <li style={{ color: 'white' }}>The applicant’s request shall be in writing on the provided request form and include reasonable detail of the proposed usage. No reservation may be made more than <u>one</u> year prior to the event.</li>
                <li style={{ color: 'white' }}>A Non-Member or Church Member that sponsors an organization or group will require a Council Member to open, check the facility afterwards for damages, and lock.</li>
                <li style={{ color: 'white' }}>An admission fee <b>WILL NOT</b> be charged for personal gain on any event that takes place on Church premises.</li>
                <li style={{ color: 'white' }}>NO church-owned property is to be taken from the premises.</li>
                <li style={{ color: 'white' }}>NO alcohol on the church property at any time.</li>
                <li style={{ color: 'white' }}>NO smoking is allowed inside any Church building.</li>
                <li style={{ color: 'white' }}>All Supplies (paper products, cutlery, tablecloths, drinks, ice, etc.) needed for events must be supplied by the user.</li>
            </ol>
            <div style={{ height: '20px' }}></div>
            <h4 className='centered-text'><u>FOR MEMBERS RESERVING:</u></h4>
            <ul className='centered-text'>
                <li style={{ color: 'white' }}><b>No fee</b> rental and <b>no fee</b> for use of Felloship Hall only <b><u>one</u></b> day prior to the event.</li>
                <li style={{ color: 'white' }}><b>$150.00</b> cleaning fee check paid to Amanda Clemens</li>
            </ul>
            <div style={{ height: '20px' }}></div>
            <h4 className='centered-text'><u>FOR MEMBERS RESERVING FOR ANY ORGANIZATION</u> for which the members belongs:</h4>
            <ul className='centered-text'>
                <li style={{ color: 'white' }}><b>$100.00</b> check paid to Quihi Bethlehem Lutheran Church & separate check of</li>
                <li style={{ color: 'white' }}><b>$150.00</b> check for cleaning paid to Amanda Clemens</li>
            </ul>
            <div style={{ height: '20px' }}></div>
            <h4 className='centered-text'><u>FOR NON-MEMBERS RESERVING:</u></h4>
            <ul className='centered-text'>
                <li style={{ color: 'white' }}><b>$250.00</b> check paid to Quihi Bethlehem Lutheran Church & separate check of</li>
                <li style={{ color: 'white' }}><b>$150.00</b> check for cleaning paid to Amanda Clemens</li>
                <li style={{ color: 'white' }}><b>$100.00*</b> check for use of Fellowhip Hall <u>each</u> day prior to the event for set-up, paid to Quihi Bethlehem Lutheran Church.</li>
            </ul>
            <div style={{ height: '20px' }}></div>
            <h4 className='centered-text'>Additional Information:</h4>
            <ol className='centered-text'>
                <li style={{ color: 'white' }}>Please submit checks and the completed request form to <u>Quihi Bethlehem Lutheran Church, 3901 FM 2676, Hondo, TX 78861.</u></li>
                <li style={{ color: 'white' }}>The user shall pay the Church the full amount at the time the contract is signed. If the event is cancelled fifteen (15) days prior to the event, the entire amount will be refunded.</li>
            </ol>
            <Form />
            <h4 className='centered-text'>General Guidelines</h4>
            <ol className='centered-text'>
                <li style={{ color: 'white' }}>The applicant’s request shall be in writing on the form provided and include proposed usage.</li>
                <li style={{ color: 'white' }}>Admission fee will will <u>not</u> be charged for personal gain on any event.</li>
                <li style={{ color: 'white' }}>No smoking nor alcohol is allowed on any church premises.</li>
                <li style={{ color: 'white' }}>Please bag & dispose of trash from Church/Chapel and dressing areas. Place in available trash dumpsters found to the left of the church. All decorations must be removed.</li>
                <li style={{ color: 'white' }}>Dirty linens should be left in the dish drainer for laundering.</li>
                <li style={{ color: 'white' }}>All lighting, air conditioning/heating, fans must be off when leaving the building.</li>
                <li style={{ color: 'white' }}><u>PLEASE NOTE:</u> The Supply Room (adjacent to the kitchen) is Church property and contents are <u>not</u> for use by renter.</li>
            </ol>
            <div style={{ height: '20px' }}></div>
            <h4 className='centered-text'><b>QUIHI BETHLEHEM LUTHERAN CHURCH WILL <u>NOT</u> BE RESPONSIBLE FOR ANY PERSONAL INJURY OR LOSS OCCURRING ON THE PREMISES.</b></h4>
            <div style={{ height: '20px' }}></div>
            <h4 className='centered-text'><b>USER IS RESPONSIBLE FOR ANY DAMAGE TO THE BUILDING OR CONTENTS.</b></h4>
            <div style={{ height: '20px' }}></div>
            <h4 className='centered-text'><b>Failure to follow these guidelines could be cause for immediate removal from the property and forfeiture of rental fee.</b></h4>
            <p className='centered-text'>By submitting this form, you agree to abide by and follow the guidelines and procedures set forth in this contract for usage of the Quihi Bethlehem Lutheran Church or Chapel / Education Building.</p>
            <div style={{ height: '20px' }}></div>
            <Footer />
        </div>
    );
};

export default FellowshipHall;
