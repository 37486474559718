import React from 'react';
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroImg2 from "../components/HeroImg2";
import PricingCard from '../components/PricingCard';
import Work from '../components/Work';

const Project = () => {
    return (
        <div>
            <NavBar />
            <HeroImg2 heading="SUNDAY EVENTS" text="Some of our Events and Activities"/>
            <Work />
            <PricingCard />
            <Footer />
        </div>
    );
};

export default Project;
