import "./PricingCardStyles.css";

import React from 'react';
import { Link } from "react-router-dom";

const PricingCard = () => {
    return (
        <div className="pricing">
            <div className="card-container">
                <div className="card">
                    <h3>- Fellowship Hall -</h3>
                    <span className="bar"></span>
                    <p className="btc">150 Guests</p>
                    <p>- Parties -</p>
                    <p>- Social Gatherings -</p>
                    <p>- Spacious -</p>
                    <Link to="/fellowship-hall" className="btn">RESERVE NOW</Link>
                </div>
                <div className="card">
                    <h3>- Church -</h3>
                    <span className="bar"></span>
                    <p className="btc">125 Guests</p>
                    <p>- Weddings -</p>
                    <p>- Formal Gatherings -</p>
                    <p>- Baptisms -</p>
                    <Link to="/church" className="btn">RESERVE NOW</Link>
                </div>
                <div className="card">
                    <h3>- Chapel -</h3>
                    <span className="bar"></span>
                    <p className="btc">15 Guests</p>
                    <p>- Smaller Gatherings -</p>
                    <p>- Personal -</p>
                    <p>- Private -</p>
                    <Link to="/chapel" className="btn">RESERVE NOW</Link>
                </div>
            </div>
        </div>
    )
};

export default PricingCard;
