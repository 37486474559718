import React, { useState, useEffect } from 'react';
import './Slideshow.css';

const images = [
    'IMG_1341.jpg',
    'IMG_1311.jpg',
    'IMG_1312.jpg',
    'IMG_1315.jpg',
    'IMG_1320.jpg',
    'IMG_1321.jpg'
];

const FellowshipHallSlideshow = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000); // Change image every 5 seconds
        return () => clearInterval(interval);
    }, []);

    return (
        <div className='slideshow-container'>
            {images.map((image, index) => (
                <div
                    key={index}
                    className={`slide ${index === currentIndex ? 'active' : ''}`}
                    style={{ backgroundImage: `url(${image})` }}
                />
            ))}
        </div>
    );
};

export default FellowshipHallSlideshow;
